<template>
  <div class="competence">
    <Header title="研发项目" index="首页" beforeTitle="研发项目" />
    <div class="content">
      <div class="topUsers">
        <div>
          <el-input
            v-model="rdParams.company_name"
            placeholder="请输入企业名称关键字"
            @keyup.enter.native="handleSearch()"
          ></el-input>
          <el-input
            v-model="rdParams.rd_name"
            placeholder="请输入项目名称关键字"
            @keyup.enter.native="handleSearch()"
          ></el-input>
          <el-button class="search" @click="handleSearch()">查询</el-button>
        </div>
        <el-button class="add" @click="addRD('')">新增</el-button>
      </div>
      <el-table
        v-loading="loading"
        element-loading-text="正在加载中···"
        :data="rd.data"
        style="width: 100%"
      >
        <el-table-column prop="company_name" label="企业名称" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{
            scope.row.company_name ? scope.row.company_name : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="area" label="所属区域" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{ scope.row.area ? scope.row.area : "-" }}</template>
        </el-table-column>
        <el-table-column prop="rd_number" label="RD编号" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{ scope.row.rd_number ? scope.row.rd_number : "-" }}</template>
        </el-table-column>
        <el-table-column prop="rd_name" label="项目名称" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{ scope.row.rd_name ? scope.row.rd_name : "-" }}</template>
        </el-table-column>
        <el-table-column prop="rd_type" label="研发类型" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span v-if="scope.row.rd_type == 1">自主研发</span>
            <span v-if="scope.row.rd_type == 2">联合开发</span>
            <span v-if="scope.row.rd_type == 3">委外研发</span>
          </template>
        </el-table-column>
        <el-table-column prop="cycle" label="周期时间" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{ scope.row.cycle ? scope.row.cycle : "-" }}</template>
        </el-table-column>
        <el-table-column prop="operation" label="操作" width="550px">
          <template slot-scope="scope">
            <el-dropdown class="psps" trigger="click">
              <span class="el-dropdown-link">
                其他材料
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item size="mini" @click.native="handleSituation(scope.row.id)">项目情况表</el-dropdown-item>
                <el-dropdown-item size="mini" @click.native="handleDecision(scope.row.id)">立项决议</el-dropdown-item>
                <el-dropdown-item size="mini" @click.native="handleplan(scope.row.id)">项目计划书</el-dropdown-item>
                <el-dropdown-item size="mini" @click.native="handlReport(scope.row.id)">验收报告</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-button class="edit" size="mini" @click="rdExport(scope.row.id)">导出</el-button>
            <el-button class="edit" size="mini" @click="addRD(scope.row.id)">编辑</el-button>
            <el-button class="del" size="mini" @click="handleDelete(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="block" v-if="rd.total > 0">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="rdParams.page"
          :page-sizes="[2, 10, 20, 40]"
          :page-size="rdParams.limit"
          layout="sizes, prev, pager, next,total,jumper"
          :total="rd.total"
        ></el-pagination>
      </div>
    </div>

    <el-dialog title="导出" :visible.sync="dialogExpoet" width="20%" top="20%">
      <div style="margin: 10px 0;">材料类型</div>
      <el-select style="width: 100%;" v-model="valueRd" multiple placeholder="请选择导出材料类型/可多选">
        <el-option label="项目情况表" value="1"></el-option>
        <el-option label="立项决议" value="2"></el-option>
        <el-option label="项目计划书" value="3"></el-option>
        <el-option label="验收报告" value="4"></el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogExpoet = false">取 消</el-button>
        <el-button type="primary" @click="exportSure()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import url from '../../components/global.vue'
import { mapGetters, mapState } from "vuex";
export default {
  inject: ["reload"],
  data () {
    return {
      exportUrl: url.userSite,
      ids: [],
      valueRd: '',
      dialogExpoet: false,
      loading: true,
      rdParams: {
        page: 1,
        limit: 10,
        company_name: "",
        rd_name: "",
        order: "id desc",
      },
    };
  },
  activated () {
    // this.handleSearch();
    this.rdParams
    this.$store.dispatch("getRDList", this.rdParams);
  },
  computed: {
    ...mapState(["rd"]),
  },
  created () {
    this.$store.dispatch("getRDList", this.rdParams);
  },
  methods: {
    exportSure () {
      if (this.ids && this.valueRd != '') {
        console.log(this.ids, this.valueRd)
        this.axios.get('/api/rd/export_files', {
          params: {
            ids: this.ids,
            type: this.valueRd
          }
        }).then((res) => {
          this.$message.success('导出成功')
          window.open(this.exportUrl + res.data.url)
        })
      } else {
        this.$message.warning('请选择导出类型')
      }
    },
    rdExport (id) {
      let arr = []
      this.ids = [...new Set(arr += id)]
      this.dialogExpoet = true
      console.log(this.ids)
      this.valueRd = ''
    },
    handleSizeChange (val) {
      this.rdParams.limit = val;
      this.$store.dispatch("getRDList", this.rdParams);
    },
    handleCurrentChange (val) {
      this.rdParams.page = val;
      this.$store.dispatch("getRDList", { ...this.rdParams });
    },
    handleSearch () {
      this.$store.dispatch("getRDList", { ...this.rdParams, page: '', limit: '' });
      this.rdParams.page = 1
    },
    addRD (id) {
      this.$router.push("/rd/add?id=" + id);
    },
    handleSituation (id) {
      this.$router.push("/rd/situation?id=" + id);
    },
    handlReport (id) {
      this.$router.push("/rd/report?id=" + id);
    },
    handleDecision (id) {
      this.$router.push("/rd/decision?id=" + id);
    },
    handleplan (id) {
      this.$router.push("/rd/plan?id=" + id);
    },
    rdEdit (id) {
      this.$router.push("/rd/rd?id=" + id);
    },
    handleDelete (id) {
      this.$confirm("确定删除此项目数据，删除后无法恢复！", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((res) => {
          this.axios.delete("/api/rd/delete", {
            params: {
              id: id,
            },
          }).then(() => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.$store.dispatch("getRDList", this.rdParams);
          });
        })
        .catch((res) => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
  beforeUpdate () {
    this.loading = false;
  },
};
</script>
<style scoped>
div/deep/.el-table__row:hover .psps {
  background: #fff !important;
  box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.16);
}
div/deep/.psps {
  background: #f2f3f9;
  color: #0f38ff;
  font-size: 12px;
  margin-right: 8px;
  padding: 1px 6px;
  cursor: pointer;
}
div/deep/.el-dialog__title {
  font-weight: bold !important;
}
</style>
